<template>
    <section>
        <div v-if="BB" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="CC" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="WW" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="PP" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="QQ" class="track">
           <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="OO" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="DD" class="track">
            <div  class="step step1 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 active"> <span class="icon"> <i class="fa fa-check"></i> </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

        <div v-if="VV" class="track">
           <div  class="step step1 cancel"> <span class="icon"> <i class="fa fa-times"></i> </span> <span :class="`text ${textClass || ''}`">ยกเลิก</span> </div>
            <div class="line none" style="display: none !important;"></div>
            <div  class="step none step2 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
        </div>

        <div v-if="NN" class="track">
           <div  class="step step1 cancel"> <span class="icon"> <i class="fa fa-times"></i> </span> <span :class="`text ${textClass || ''}`">ไม่อนุมัติใบเสนอราคา</span> </div>
            <div class="line none" style="display: none !important;"></div>
            <div  class="step none step2 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
        </div>

        <div v-if="UU" class="track">
           <div  class="step step1 cancel"> <span class="icon"> <i class="fa fa-times"></i> </span> <span :class="`text ${textClass || ''}`">ยกเลิกโดยผู้ใช้</span> </div>
            <div class="line none" style="display: none !important;"></div>
            <div  class="step none step2 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
            <div class="line none lineca"></div>
            <div  class="step none step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`"></span> </div>
        </div>

        <div v-if="deft" class="track">
           <div  class="step step1 "> <span class="icon"> </span> <span :class="`text ${textClass || ''}`">รออนุมัติใบเสนอราคา</span> </div>
            <div class="line"></div>
            <div  class="step step2 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">อนุมัติใบเสนอราคา</span> </div>
            <div class="line lineca"></div>
            <div  class="step step3 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">รอชำระเงิน</span> </div>
            <div class="line lineca"></div>
            <div  class="step step4 "> <span class="icon">  </span> <span :class="`text ${textClass || ''}`">ชำระเงินแล้ว</span> </div>
            <div class="line lineca"></div>
            <div  class="step step5 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step6 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">จัดส่งสินค้า</span> </div>
            <div class="line lineca"></div>
            <div  class="step step7 "> <span class="icon">  </span> <span  :class="`text ${textClass || ''}`">รับสินค้าแล้ว</span> </div>
        </div>

    </section>
</template>


<script>
    export default {
        name: 'StatusHistoryvfix',

        props: [
            "status",
            "textClass"
        ],
        data: function () {
            return {
                BB: false,
                CC: false,
                WW: false,
                PP: false,
                QQ: false,
                OO: false,
                DD: false,
                VV: false,
                NN: false,
                UU: false,
                deft: false,
            }
        },
        mounted() {
            this.checkStatus();
        },
        methods: {
            async checkStatus() {
                switch (this.status) {
                    case 'B':
                        this.BB = true;
                        break;
                    case 'C':
                        this.CC = true;
                        break;
                    case 'W':
                        this.WW = true;
                        break;
                    case 'P':
                        this.PP = true;
                        break;
                    case 'Q':
                        this.QQ = true;
                        break;
                    case 'O':
                        this.OO = true;
                        break;
                    case 'D':
                        this.DD = true;
                        break;
                    case 'V':
                        this.VV = true;
                        break;
                    case 'N':
                        this.NN = true;
                        break;
                    case 'U':
                        this.UU = true;
                        break;
                    default:
                        this.deft = true;
                }
            }

        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

    .track {
        position: relative;
        background-color: none;
        height: 1px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 50px;
        margin-top: 40px;
        //margin-left:-40px;
    }

    .line {
        position: relative;
        background-color: #CCC;
        height: 1px;
        width: 40%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .track .step {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        width: 14%;
        margin-top: -18px;
        text-align: center;
        position: relative
    }

    .track .step.active .icon {
        background: #5cb85c;
        color: #fff;
        padding-top: 5px;
        font-size: 18px;
    }

    .track .step.cancel .icon {
        background: #55246c;
        color: #fff
    }

    .track .step.none .icon {
        background: none;
        color: #fff
    }

    .track .icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: relative;
        border-radius: 100%;
        background: #ddd
    }

    .icon>.fa {
        font-size: 15px !important;
        margin-top: 8px;
    }

    .track .step.active .text {
        font-weight: 400;
        color: #000;
    }

    .lineca.none {
        background: none;
    }

    .track .text {
        font-size: 15px !important;
        display: block;
        margin: 7px -20px 0 -20px;
    }

    .track .textr {
        font-size: 15px !important;
        display: block;
        margin: 7px -20px 0 -20px;
    }

    .w15pb03 {
        width: 15%;
        padding-bottom: .3em;
    }

    .w15pb03img {
        width: 15%;
        padding-bottom: .3em;
    }

    .w40 {
        width: 40%;
        padding-bottom: .3em;
    }

    @media screen and (max-width: 1250px) {
        //  .track {
        //margin-left:-30px;
        //  }
    }

    @media screen and (max-width: 992px) {
        .text-sm-center {
            text-align: center;
        }

        .p-sm-0 {
            padding: 0;
        }
    }

    @media screen and (max-width: 768px) {

        //  .track {
        //margin-left:0px;
        //  }
        .track .textr {
            margin: 7px -15px 0 -15px;
        }

        .w15pb03img {
            width: 30%;
            padding-bottom: .3em !important;
        }

        .w40 {
            width: 70%;
        }

        .pm-xs-0 {
            padding: 0;
            margin: 0;
        }
    }
</style>