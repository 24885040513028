<template>
  <section>
    <div class="row m-3">
      <div class="col-12 p-0 m-0">
        <button type="button" :class="`btn bt w-100 bg-${buCode}`" data-dismiss="modal"
          @click="goPrint(0)">พิมพ์ใบยืนยันการชำระเงิน</button>
      </div>
    </div>

    <!-- //////////////////////////// Print Page ////////////////////////////// -->
    <div v-show="false" >
      <div class="container  print-Div" :id="`printDetail-${cartref}`">
        <div class="row p-3 border-bottom d-flex justify-content-center">
          <VueBarcode :value="cartref">
                ไม่สามารถสร้าง Barcode ได้
          </VueBarcode>
        </div>
        <div class="px-2 pt-2">
          <div class="row  my-2">
            <div class="col-12 p-0 ">
              <h3 class="pl-5">เลขที่รายการ {{ listData[0][0]["#ref"] }} ({{ qtSaleSource }})</h3>
              <h4 class="pl-5">วันที่สั่งซื้อ {{ listData[0][0]["#orderdate"] }}</h4>
              <table class="table table-borderless text-center">
                <thead>
                  <tr>
                    <th scope="col">สถานะรายการ</th>
                    <th scope="col">จัดส่งโดย</th>
                    <th scope="col">สาขา</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ listData[0][0]["#sttname"] }}</td>
                    <td>{{dlvType.D ? 'จัดส่ง' : ''}}{{dlvType.D && dlvType.P ? '/' : ''}}{{dlvType.P ? 'รับที่สาขา' : ''}}</td>
                    <td>{{ listData[0][0]["#sorderstore"] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-12 p-0 ">
                <StatusHistoryvfix v-if="getQTSALESOURCE=='UV'" :status="qtSoStatus" />
                <StatusHistory v-else :status="qtSoStatus" />
            </div>
          </div> -->
          <div class="row my-2">
            <h4>รายละเอียดสินค้า</h4>
            <div class="col-12" >
              <table class="table table-borderless">
                  <tbody>
                    <tr v-for="(e, index) in listData[1]" :key="index">
                      <td class="p-0">
                        <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKC" :key="e.SKC" v-if="!(e.SKC == '')"/>
                      </td>
                      <td class="py-1">{{ e.PRN }} <br>({{ e.SKC }}) <span v-if="e.SKC != '154338'">{{ e.DLVTEXT }}({{ e.DLV }}) วันที่:{{ e.DLVDATE }} สาขาส่ง:{{e.deliveryByStore}}</span></td>
                      <td class="py-1" v-if="qtSaleSource=='UV'">{{ e.NORCOST }}</td>
                      <td class="py-1">{{ e.UPC }}</td>
                      <td class="py-1">{{ e.QNT }}</td>
                      <td class="py-1">{{ e.AMT }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </div>
          <div class="row my-2">
            <h4>รายละเอียดการชำระเงิน</h4>
            <div class="col-12" >
              <div class="row">
                <div class="col"><p>ยอดเงิน</p></div>
                <div class="col text-end">
                  <p>{{ getSumAMT }} บาท</p>
                  <p></p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <p>ส่วนลดตามรายการ</p>
                  <p>ส่วนลด MKT</p>
                  <p>ส่วนลดพนักงาน</p>
                </div>
                <div class="col text-end">
                  <p>{{ discount }} บาท</p>
                  <p>{{ listData[0][0]["#discmkt"] }} บาท</p>
                  <p>{{ showDisStaff }} บาท</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col">ราคาสุทธิ</div>
                <div class="col text-end">{{ sumNET }} บาท</div>
              </div>
              <hr>
              <div v-if="qtSaleSource=='UV'">
                <br>
                <div class="row">
                  <div class="col">
                    <p>Cost (706)</p>
                    <p>TOtal (706)</p>
                    <p>GP</p>
                  </div>
                  <div class="col text-end">
                    <p>{{ listData[0][0]["#cost706"] }} บาท</p>
                    <p>{{ listData[0][0]["#amt706"] }} บาท</p>
                    <p>{{ listData[0][0]["#gp"] }} %</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-2" v-if="listRows[6] != 0">
            <h4 class="">ข้อมูลส่วนลด</h4>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Seq</th>
                  <th scope="col">Product</th>
                  <th scope="col">Quant</th>
                  <th scope="col">Disc</th>
                  <th scope="col">Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ listData[6][0].SEQ }}</td>
                  <td>{{ listData[6][0].NME }}</td>
                  <td>{{ listData[6][0].QNT }}</td>
                  <td>{{ listData[6][0].DSC }}</td>
                  <td>{{ listData[6][0].REM }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row my-2">
            <h4 class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-truck" viewBox="0 0 16 16" >
                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
              ที่อยู่จัดส่ง
            </h4>
            <div class="col-12">
              <table class="table table-borderless table-striped container-fluid">
                <tbody>
                  <tr v-for="(e, index) in listData[2]" :key="index">
                    <td class="px-0" style="font-size:1rem;" v-if="e.value">{{ e.key }}</td>
                    <td class="px-0" style="font-size:1rem;" v-if="e.value">{{ e.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row my-2" v-if="listRows[7] != 0">
            <h4 class="">
              <i class="fas fa-sticky-note"></i> ที่อยู่ใบกำกับภาษี/เลขที่ประจำตัวผู้เสียภาษี
            </h4>
            <div class="col-12">
              <table class="table table-borderless table-striped container-fluid">
                <tbody>
                  <tr v-for="(e, index) in listData[7]" :key="index">
                    <td class="px-0" style="font-size:1rem;" v-if="e.value">{{ e.key }}</td>
                    <td class="px-0" style="font-size:1rem;" v-if="e.value">{{ e.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12 p-0 m-0">
              <h4 class="">ข้อมูลการชำระเงิน</h4>
            </div>
            <div class="col-12">
              <p>ไม่พบข้อมูลการชำระเงิน</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import ImageBySKU from '@/components/ImageBySKU.vue';
  import printJS from 'print-js'
  import StatusHistoryvfix from '@/components/Menu/StatusHistoryvfix.vue';
  import StatusHistory from '@/components/Menu/StatusHistory.vue';

  export default {
    name: "PrintHistoryDetail",
    data: function () {
      return {
      }
    },
    components: {
      ImageBySKU,
      StatusHistoryvfix,
      StatusHistory

    },
    props: [
      "cartref",
      "listData",
      "listRows",
      "qtSaleSource",
      "qtSoStatus",
      "getSumAMT",
      "buCode",
      "dlvType",
      "sumNET",
      "discount",
      "showDisStaff"
    ],
    methods: {

      async goPrint(mode) {
        this.checkPickUp()
        try {
          // let data = {
          //   "REF": this.cartref,
          //   "STCODE": this.stcode
          // }
          // let getAPI = await serviceAPI.call_API('post', `history/getQuotationHeader`, data, 'auth');
          // this.listData = getAPI.data.dbitems[0];
          

          // let data1 = {
          //   "ref": this.cartref
          // }
          // let getAPI1 = await serviceAPI.call_API('post', `history/ShowEditQuotation`, data1, 'auth');
          // let getDataAPI1 = getAPI1.data.dbitems;
          // let getDataAPI1Length = getAPI1.data.dbitems.length;
          // if(getDataAPI1Length>0){
          //   this.customQT = JSON.parse(getDataAPI1[0].EDITADDR);
          // }
          

          // mode == 0 ? this.m0 = true : this.m0 = false;
          // mode == 1 ? this.m1 = true : this.m1 = false;
          // mode == 2 ? this.m2 = true : this.m2 = false;
          // this.getStyle = `
          //   .title-name{
          //     font-weight: 900 !important;
          //     margin-bottom: 0 !important;
          //     font-size: 1.2rem;
          //   }
          //   @font-face {
          //     font-family: 'kittithadalight45regular';
          //     src: url('/fonts/kittithadalight45-webfont.woff2') format('woff2'),
          //       url('/fonts/kittithadalight45-webfont.woff') format('woff');
          //     font-weight: normal;
          //     font-style: normal;
          //   }

          //   * {
          //     font-family: 'kittithadalight45regular';
          //     font-size: 20px;
          //   }
          //   .title-little{
          //     font-size: 0.8rem;
          //   }
          //   .line-low{
          
          //   }
        
          // `;
          setTimeout(() => {
            printJS({
              printable:`printDetail-${this.cartref}`,
              type: 'html',
              maxWidth:1000,
              css: '/css/bt4forprint.css',
              // style: this.getStyle,
              font_size:null
            });
          }, 500);

        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      priceBath(para_price) {
        return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
      },
      async checkPickUp(){
        let status = this.pickUpStatus
        if(status == 'PickupNow'){
          this.pickUpText = 'รับสินค้าที่สาขาทันที';
        } else if(status == 'Pickup'){
          this.pickUpText = 'รับสินค้าที่สาขาตามเวลา'
        } else if(status == 'Delivery'){
          this.pickUpText = 'จัดส่งสินค้า'
        }
      }

    },
    computed: {
      getBU: function () {
        return this.$store.getters['customer/getQTBU']
      },
      getQTSALESOURCE: function () {
          return this.$store.getters['auth/getQTSALESOURCE']
      },
    }
  };
</script>

<style scoped lang="scss">
  .bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .print-Div{
    width: 1000px;
  }
</style>